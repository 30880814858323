import { Box } from "@mui/material";
import { Filter } from "./LeftColumn/Filter/Filter";
import { WorkshopList } from "./LeftColumn/WorkshopList";
import { WorkshopInfo } from "./RightColumn/WorkshopInfo";
import { useContext, useEffect, useState } from "react";
import {
  Workshops as WorkshopsType,
  CourseEvent,
  DisabledEvent,
} from "../types";
import {
  FilterCourseEvents,
  createCourseEventList,
} from "../utils/courseUtils";
import { AccountProperties, AccountPropertiesContext } from "./SSO/SignIn";
import { WorkshopInfoPlaceHolder } from "./RightColumn/WorkshopInfoPlaceholder";
import { getOutlookCourse } from "../services/GraphService";

export function Workshops({
  courses,
  events,
  userEvents,
  teacherEvents,
  ReloadEvents,
}: WorkshopsType) {
  // AccountProperties Context from the SignIn.tsx
  const { accessToken, userInfo } = useContext(
    AccountPropertiesContext
  ) as AccountProperties;

  const [filterQuery, setFilterQuery] = useState<string>("");
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [selectedCourse, setSelectedCourse] = useState<any>(null);
  const [courseEventList, setCourseEventList] = useState<CourseEvent[]>([]);
  const [disabledEvents, setDisabledEvents] = useState<DisabledEvent[]>([]);
  const [canAutoSelect, setCanAutoSelect] = useState<boolean>(false);

  useEffect(() => {
    const courseEvents = createCourseEventList(courses, events, userEvents, teacherEvents, userInfo);
    
    const filteredCourseEvents = FilterCourseEvents(
      courseEvents,
      selectedFilter,
      filterQuery,
      userInfo
    );
    setCourseEventList(filteredCourseEvents);
    if (selectedCourse) {
      const containsSelectedButton = filteredCourseEvents.filter(
        (courseEvent: CourseEvent) =>
          courseEvent.courseCode === selectedCourse.courseCode
      );
      if (containsSelectedButton.length === 0) {
        setSelectedCourse(null);
      }
    }
  }, [
    courses,
    events,
    userEvents,
    filterQuery,
    selectedFilter,
    selectedCourse,
  ]);

  // Called when catagory filter button is clicked.
  const CatagoryButtonClicked = (catagoryName: string) => {
    if (selectedFilter === catagoryName) {
      setSelectedFilter("");
    } else {
      setSelectedFilter(catagoryName);
    }
  };

  const ChangeSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterQuery(event.target.value.toLowerCase());
  };


  const AttendeesConfirmed = async (code: string) => {
    ReloadEvents().then(([events, userEvents, eventsAttendees]) => {
      const courseEvents = createCourseEventList(courses, events, userEvents, eventsAttendees, userInfo);
      const course = courseEvents.find(
        (courseEvent: CourseEvent) => courseEvent.courseCode === code
      );
      setSelectedCourse(course);
    });
  };

  const ClickedOnCourse = async (code: string) => {
    setCanAutoSelect(false);
    const course = courseEventList.find(
      (course: CourseEvent) => course.courseCode === code
    );
    setSelectedCourse(course);
    const disabledList: any[] = [];
    for (const event of (course as CourseEvent).events) {
      const outlookEvent = await getOutlookCourse(
        accessToken,
        course as CourseEvent,
        event
      );
      disabledList.push({
        eventId: event.workshopId,
        available: outlookEvent ? outlookEvent.value.length !== 0 : false,
      });
    }
    setDisabledEvents(disabledList);
    setCanAutoSelect(true);
  };

  const enrolledForCourse = async (code: string) => {
    ReloadEvents().then(([events, userEvents, userInfo]) => {
      const courseEvents = createCourseEventList(courses, events, userEvents, teacherEvents, userInfo);
      const course = courseEvents.find(
        (courseEvent: CourseEvent) => courseEvent.courseCode === code
      );
      setSelectedCourse(course);
    });
  };

  return (
    <>
      <Box
        display="flex"
        paddingTop="45px"
        height="calc(100vh - 50px)"
        margin="2%"
      >
        <Box className={"LeftColumn"} width="50%" height="100%" marginLeft="2%">
          <Filter
            selectedFilter={selectedFilter}
            ChangeSearchInput={ChangeSearchInput}
            CatagoryButtonClicked={CatagoryButtonClicked}
          />
          <WorkshopList
            courseEventList={courseEventList}
            selectedCourse={selectedCourse}
            ClickedOnCourse={ClickedOnCourse}
          />
        </Box>
        <Box
          className={"RightColumn"}
          width="50%"
          height="calc(90% + 16px)"
          marginLeft="4%"
          marginRight="2%"
        >
          {selectedCourse !== null ? (
            <WorkshopInfo
              selectedCourse={selectedCourse}
              setSelectedCourse={setSelectedCourse}
              enrolledForCourse={enrolledForCourse}
              AttendeesConfirmed={AttendeesConfirmed}
              disabledEvents={disabledEvents}
              canAutoSelect={canAutoSelect}
            />
          ) : (
            <WorkshopInfoPlaceHolder />
          )}
        </Box>
      </Box>
    </>
  );
}
