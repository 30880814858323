import {
  AuthenticatedTemplate,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../../services/AuthConfig";
import { createContext, useEffect, useState } from "react";
import { GetProfilePicture } from "../../services/GetProfilePicture";
import { GetEmployeeInfo } from "../../services/AfasConnectors";

export type userInfo = {
  Initials: string;
  user: string;
  userEmail: string;
  userExternalId: number;
  userInternalId: string;
};

export type AccountProperties = {
  accessToken: string;
  profileName: string;
  profilePicture: string;
  homeAccountId: string;
  userInfo: userInfo | undefined;
};

export const AccountPropertiesContext = createContext<AccountProperties | null>(
  null
);

export default function SignIn({ children }: any) {
  const { instance } = useMsal();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [accessToken, setAccessToken] = useState<string>("");
  const [userInfo, setUserInfo] = useState<userInfo>();
  const [profileName, setProfileName] = useState<string>("");
  const [profilePicture, setProfilePicture] = useState<string>("");
  const [homeAccountId, setHomeAccountId] = useState<string>("");

  const { result } = useMsalAuthentication(
    InteractionType.Redirect,
    loginRequest
  );
  useEffect(() => {
    instance.initialize().then(() => {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        instance
          .acquireTokenSilent({ scopes: ["user.read","Calendars.ReadWrite.Shared"], account: accounts[0] })
          .then((response) => {
            setAccessToken(response.accessToken);
            GetProfilePicture(response.accessToken).then((res) => {
              setProfileName(response.account?.username || "");
              setProfilePicture(res ? URL.createObjectURL(res) : "");
              setHomeAccountId(response.account?.homeAccountId || "");
              if (response.account?.username) {
                GetEmployeeInfo(response.account?.username || "").then(
                  (empInfo) => {
                    setUserInfo(empInfo);
                    setLoggedIn(true);
                  }
                );
              }
            });
          });
      } else {
        setAccessToken(result?.accessToken || "");
        GetProfilePicture(result?.accessToken || "").then((res) => {
          setProfileName(result?.account?.name || "");
          setProfilePicture(res ? URL.createObjectURL(res) : "");
          setHomeAccountId(result?.account?.homeAccountId || "");
          if (result?.account?.name) {
            GetEmployeeInfo(result?.account?.name || "").then((empInfo) => {
              setUserInfo(empInfo);
              setLoggedIn(true);
            });
          }
        });
      }
    });
  }, [result, instance]);

  return (
    <AuthenticatedTemplate>
      <AccountPropertiesContext.Provider
        value={{
          accessToken,
          profileName,
          profilePicture,
          homeAccountId,
          userInfo,
        }}
      >
        {isLoggedIn ? children : ""}
      </AccountPropertiesContext.Provider>
    </AuthenticatedTemplate>
  );
}
