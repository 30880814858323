import {
  Box,
  Button,
  Card,
  CircularProgress,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { InfoCardSection } from "./InfoCardSection";
import { EventTable } from "./EventTable";
import { useContext, useEffect, useRef, useState } from "react";
import { AddAttendee, DeleteAttendee } from "../../services/AfasConnectors";
import { AccountProperties, AccountPropertiesContext } from "../SSO/SignIn";
import {
  RemoveCalendarEvent,
  SendEventInvite,
} from "../../services/GraphService";
import AttendeesModal from "./AttendeesModal";
import {
  DisabledEvent,
  Event,
  WorkshopInfo as WorkshopInfoType,
} from "../../types";
import "../../styles/WorkshopInfo.css";
import adminUsers from '../../admin_rights_users.json';


const ConfirmAttendeesButton = styled(Button)<any>(({ theme, isUserHost }) => ({
  color: "white",
  backgroundColor: isUserHost ? "var(--twd_red)" : "var(--twd_navy)",
  borderRadius: "12px",
  width: "20%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "none",
  "&:hover": {
    backgroundColor: isUserHost ? "var(--twd_red)" : "var(--twd_aqua)",
    transform: "scale(0.97)",
  },
  "&:disabled": {
    backgroundColor: "var(--twd_grey)",
    transform: "scale(0.97)",
  },
}));

const AddButton = styled(Button)<any>(({ theme, isenrolled }) => ({
  color: "white",
  backgroundColor: isenrolled ? "var(--twd_red)" : "var(--twd_navy)",
  borderRadius: "12px",
  width: "10%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "none",
  "&:hover": {
    backgroundColor: isenrolled ? "var(--twd_red)" : "var(--twd_aqua)",
    transform: "scale(0.97)",
  },
  "&:disabled": {
    backgroundColor: "var(--twd_grey)",
    transform: "scale(0.97)",
  },
}));



function EnrollButton({
  selectedButton,
  disabledEvents,
  AddButtonClicked,
  RemoveButtonClicked,
  isUserHost,
  onConfirmAttendeesClick,
}: any) {
  const isAvailableOutlook: DisabledEvent = disabledEvents.find(
    (disabledEvent: any) => disabledEvent.eventId === selectedButton.workshopId
  ) || { eventId: "", available: false };
  const isAddButtonDisabled: boolean =
    Object.keys(selectedButton).length === 0 ||
    (!selectedButton.isEnrolled && !isAvailableOutlook.available);

  // const isConfirmAttendeesDisabled: boolean = Object.keys(selectedButton).length === 0;

  const isConfirmAttendeesDisabled: boolean =
  Object.keys(selectedButton).length === 0 ||
  disabledEvents.some((event: { eventId: number; available: boolean }) => (event.eventId === selectedButton.workshopId && event.available === false));
    
  return (
    <>
      {isUserHost ? (
        <ConfirmAttendeesButton
          onClick={onConfirmAttendeesClick}
          disabled={isConfirmAttendeesDisabled}
        >
          <Typography sx={{ fontSize: "0.85rem", fontWeight: "bold" }}>
            Confirm Attendees
          </Typography>
        </ConfirmAttendeesButton>
      ) : (
        <AddButton
          onClick={() =>
            selectedButton.isEnrolled
              ? RemoveButtonClicked()
              : AddButtonClicked()
          }
          disabled={isAddButtonDisabled}
          isenrolled={selectedButton.isEnrolled}
        >
          <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
            {selectedButton.isEnrolled ? "Remove" : "Add"}
          </Typography>
        </AddButton>
      )}
    </>
  );
}

export function WorkshopInfo({
  selectedCourse,
  setSelectedCourse,
  enrolledForCourse,
  AttendeesConfirmed,
  disabledEvents,
  canAutoSelect,
}: WorkshopInfoType) {
  // AccountProperties Context from the SignIn.tsx
  const { accessToken, userInfo } = useContext(
    AccountPropertiesContext
  ) as AccountProperties;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [needsScrollbar, setNeedsScrollbar] = useState(false);
  // State for the selected Event.
  const [selectedEvent, setSelectedEvent] = useState<Event | {}>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { Initials, userEmail } = userInfo || {};

  const isHost = (selectedEvent: Event) => {
    return selectedEvent && (Initials === selectedEvent.presenter || adminUsers.some((user:any) => userEmail === user.userEmail));
  };

  const isUserHost = isHost(selectedEvent as Event);
  // declare showAttendees state
  const [showAttendees, setShowAttendees] = useState(false);
  useEffect(() => {
    // Reset showAttendees to false whenever the component is accessed
    setShowAttendees(false);
  }, [selectedCourse]);
  
  const handleConfirmAttendeesClick = () => {
    // Set the state to indicate that the modal should be opened
    setShowAttendees(true);
  };


  const handleCloseModal = () => {
    setShowAttendees(false);
  };

  const handleResize = () => {
    const container = containerRef.current;

    if (container) {
      if (container.scrollHeight > container.clientHeight) {
        // Content overflows, so add an extra div for the scrollbar
        setNeedsScrollbar(true);
      } else {
        setNeedsScrollbar(false);
      }
    }
  };

  useEffect(() => {
    // Check and set the need for scrollbar on component mount
    handleResize();

    // Attach a 'resize' event listener to the window
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const allEvents = [...selectedCourse.events, ...selectedCourse.userPastEvents];
  
    if (allEvents.length > 0 && canAutoSelect) {
      for (const event of allEvents) {
        const isUserEventHost = isHost(event as Event);
        const isDisabled = disabledEvents.find((e: any) => e.eventId === event.workshopId);
        const isEventFull = event.attendeesCount === event.attendeesMax;
        
        // Check if the event is not full and is not disabled
        if ((!isUserEventHost && !isEventFull && isDisabled && isDisabled.available) || (isUserEventHost && isDisabled && isDisabled.available) || (isUserEventHost && !isDisabled)) {
          setSelectedEvent(event);
          break;
        }
        else{
          setSelectedEvent({});
        }
      }
    } else if (allEvents.length === 0) {
      setSelectedEvent({});
    }
  }, [selectedCourse, canAutoSelect, disabledEvents]);

  // Function that is called when the workshop Add button is clicked.
  const AddButtonClicked = async () => {
    console.log("Add button is clicked!");
    setIsLoading(true);
    setSelectedEvent({});
    await AddAttendee(userInfo, (selectedEvent as Event).workshopId);
    SendEventInvite(
      accessToken,
      userInfo,
      selectedEvent,
      selectedCourse.course.workshopTitle
    );
    enrolledForCourse(selectedCourse.courseCode);
    setIsLoading(false);
    console.log("User is added!");
  };

  // Function that is called when the workshop Remove button is clicked
  const RemoveButtonClicked = async () => {
    console.log("Remove button is clicked!");
    setIsLoading(true);
    setSelectedEvent({});
    await DeleteAttendee(userInfo, (selectedEvent as Event).workshopId);
    RemoveCalendarEvent(
      accessToken,
      userInfo,
      selectedEvent,
      selectedCourse.course.workshopTitle
    );
    enrolledForCourse(selectedCourse.courseCode);
    setIsLoading(false);
    console.log("User is removed!");
  };
  return (
    <>
      <Card className={"WorkshopBox"}>
        <Box className={"WorkshopBoxHeader"}>
          <Box className={"WorkshopBoxHeaderTextBox"}>
            <Typography className={"WorkshopBoxHeaderText"}>
              {selectedCourse.course.workshopTitle}
            </Typography>
          </Box>
          {selectedCourse.catagoryIcon ? (
            <selectedCourse.catagoryIcon className={"WorkshopBoxHeaderImage"} />
          ) : (
            ""
          )}
        </Box>
        {showAttendees &&
        [...selectedCourse.events, ...selectedCourse.userPastEvents].map((event) => (
          event.teacherEventAttendees &&
          event.teacherEventAttendees[event.workshopId] &&
          event.teacherEventAttendees[event.workshopId].attendees &&
          event.teacherEventAttendees[event.workshopId].attendees.length > 0 && (
            <AttendeesModal
              selectedButton={selectedEvent}
              selectedCourse={selectedCourse}
              setSelectedCourse={setSelectedCourse}
              AttendeesConfirmed={AttendeesConfirmed}
              key={event.workshopId}
              onClose={handleCloseModal}
            />
          )
        ))
      }
        <Box
          className={"WorkshopScrollBox"}
          ref={containerRef}
          sx={{
            overflowY: "auto",
          }}
        >
          <Box width="100%">
            <Box width="calc(100% + 16px + 1%)">
              <Box className={"WorkshopBoxInfo"}>
                <InfoCardSection
                  header="Target Audience"
                  info={selectedCourse.course.Target_audience}
                />
                <InfoCardSection
                  header="Goal"
                  info={selectedCourse.course.Goal}
                />
                <InfoCardSection
                  header="Description"
                  info={selectedCourse.course.Description}
                />
              </Box>
              <Box className={"WorkshopTimeBox"}>
                {selectedCourse.events.length > 0 && (
                  <EventTable
                    events={selectedCourse.events}
                    selectedButton={selectedEvent}
                    setSelectedButton={setSelectedEvent}
                    isPastEvents={false}
                    disabledEvents={disabledEvents}
                  />
                )}
                
                {selectedCourse.userPastEvents.length > 0 && (
                  <EventTable
                    events={selectedCourse.userPastEvents}
                    selectedButton={selectedEvent}
                    setSelectedButton={setSelectedEvent}
                    isPastEvents={true}
                    disabledEvents={disabledEvents}
                  />
                )}
              </Box>
            </Box>
          </Box>
          {needsScrollbar ? "" : <Box width="15px"></Box>}
        </Box>
        <Box
          height="5%"
          borderTop="2px solid lightgray"
          sx={{
            display: "flex",
            padding: "3%",
            justifyContent: "right",
          }}
        >
          {isLoading ? (
            <CircularProgress
              sx={{
                color: "var(--twd_aqua)",
                marginRight: "20px",
                width: "10%",
              }}
            />
          ) : (
            <EnrollButton
              selectedButton={selectedEvent}
              disabledEvents={disabledEvents}
              AddButtonClicked={AddButtonClicked}
              RemoveButtonClicked={RemoveButtonClicked}
              isUserHost={isUserHost}
              onConfirmAttendeesClick={handleConfirmAttendeesClick}
            />
          )}
        </Box>
      </Card>
    </>
  );
}
