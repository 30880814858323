import { Avatar, IconButton, Menu } from "@mui/material";
import { SignOutButton } from "../SSO/SignOut";
import { useState } from "react";
import "../../styles/NavBar.css";

export function ProfileButton({ profileName, profilePicture }: any) {
  // States
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openLogoutMenu, setOpenLogoutMenu] = useState<boolean>(false);

  // Function called when the profile picture button is pressed, sets the position of the menu to the position of the button.
  const handleLogoutMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenLogoutMenu(true);
  };

  // When anything other than the menu is pressed than the menu will close.
  const handleCloseLogout = () => {
    setAnchorEl(null);
    setOpenLogoutMenu(false);
  };

  return (
    <>
      <IconButton
        className="profilebutton"
        size="small"
        edge="end"
        aria-label="menu"
        onClick={handleLogoutMenu}
      >
        <Avatar className="profileavatar" src={profilePicture}>
          {profileName[0]}
        </Avatar>
      </IconButton>
      <Menu
        className="navbarmenu"
        anchorEl={anchorEl}
        open={openLogoutMenu}
        onClose={handleCloseLogout}
        keepMounted
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <SignOutButton />
      </Menu>
    </>
  );
}
