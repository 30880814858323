import { format } from "date-fns";

const afasToken =
  "AfasToken PHRva2VuPjx2ZXJzaW9uPjE8L3ZlcnNpb24+PGRhdGE+NkRFQUNCOUVFNTBFNDlGRjk2MzNFRTlDRjREQzA4MTA1OUNGRjJERjQ0NUQ3REU5NkYzQTRBOTIzMkM4MjFENjwvZGF0YT48L3Rva2VuPg==";

const afasURL = "https://87674.rest.afas.online/ProfitRestServices/connectors";

// Test
// const afasToken =
//   "AfasToken PHRva2VuPjx2ZXJzaW9uPjE8L3ZlcnNpb24+PGRhdGE+OTA0OUQ4RTU4RERENDFEMEEyMjcxOEFERDQ4QTAwNTdDRkEzQTIyNzA2MTI0NzUyQTkzNTI5RTdDMDFEODgwQzwvZGF0YT48L3Rva2VuPg==";
//
// const afasURL =
//   "https://87674.resttest.afas.online/ProfitRestServices/connectors";

const headersAfas = {
  "content-type": "application/json",
  Authorization: afasToken,
};

export async function GetAllCourses() {
  const response = await fetch(`${afasURL}/TD_Courses`, {
    headers: headersAfas,
  });
  const jsonResponse = await response.json();
  return jsonResponse.rows;
}

export async function GetAllEvents() {
  const response = await fetch(`${afasURL}/TD_Events?skip=-1&take=-1&orderbyfieldids=workshopDate`, {
    headers: headersAfas,
  });

  const jsonResponse = await response.json();
  return jsonResponse.rows;
}

export async function GetAllHostAttendees() {
  const response = await fetch(
    `${afasURL}/TD_attendees_presence?skip=-1&take=-1`,
    { headers: headersAfas }
  );

  const jsonResponse = await response.json();
  return jsonResponse.rows;
}

export async function GetWorkshopAttendees(workshopId: number | undefined) {
  const response = await fetch(
    `${afasURL}/TD_attendees_presence?filterfieldids=workshopId&filtervalues=${workshopId}&operatortypes=1`,
    { headers: headersAfas }
  );

  const jsonResponse = await response.json();
  return jsonResponse.rows;
}

export async function GetAllEmployeeEvents(employee: string | undefined) {
  const response = await fetch(
    `${afasURL}/TD_Employee_Events?filterfieldids=attendeeABB&filtervalues=${employee}&operatortypes=1`,
    { headers: headersAfas }
  );

  const jsonResponse = await response.json();
  return jsonResponse.rows;
}

export async function GetEmployeeInfo(email: string) {
  const response = await fetch(
    `${afasURL}/TD_Contacts?filterfieldids=userEmail&filtervalues=${email}&operatortypes=1`,
    {
      headers: headersAfas,
    }
  );

  const jsonResponse = await response.json();
  return jsonResponse.rows[0];
}

export async function AddAttendee(userInfo: any, courseId: number) {
  const date = format(new Date(), "yyyy-MM-dd");
  const jsonString = {
    KnCourseMember: {
      Element: {
        "@CrId": courseId, // event ID
        "@CdId": userInfo.userExternalId, //External Contact ID
        Fields: {
          BcCo: userInfo.userInternalId, // Internal ID
          SuDa: date,
          Invo: false,
        },
      },
    },
  };
  const response = await fetch(`${afasURL}/KnCourseMember`, {
    method: "POST",
    headers: headersAfas,
    body: JSON.stringify(jsonString),
  });
  const responseTxt = await response.text();
  return JSON.parse(responseTxt);
}

export async function confirmAttendee(usersInfo: any, workshopId: number) {
  const confirmRequests = usersInfo.map(async (user: any) => {
    if (user.presence !== user.toConfirm) {
      const jsonString = {
        KnCourseSessionMember: {
          Element: {
            "@CrId": workshopId,
            "@SeId": 1,
            "@MeId": user.userInternalID,
            "@CdId": user.userExternalID,
            Fields: {
              Pres: user.toConfirm,
            },
          },
        },
      };

      const response = await fetch(`${afasURL}/KnCourseSessionMember`, {
        method: "PUT",
        headers: headersAfas,
        body: JSON.stringify(jsonString),
      });
    }});
    // Wait for all requests to complete
    await Promise.all(confirmRequests);
}

export async function DeleteAttendee(userInfo: any, courseId: number) {
  const userExternalId = userInfo.userExternalId;
  const userInternalId = userInfo.userInternalId;
  const date = format(new Date(), "yyyy-MM-dd");
  const response = await fetch(
    `${afasURL}/KnCourseMember/KnCourseMember/@CrId,@CdId,BcCo,SuDa,Invo/${courseId},${userExternalId},${userInternalId},${date},false`,
    {
      method: "DELETE",
      headers: headersAfas,
    }
  );
  const error = await response.text();
  console.log(error);
}
