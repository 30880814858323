import { Box, Typography } from "@mui/material";

type LeftCardSectionType = {
  header: string;
  info: string;
};

export function InfoCardSection({ header, info }: LeftCardSectionType) {
  return (
    <Box marginBottom="20px">
      <Typography
        align="left"
        variant="h6"
        component="div"
        sx={{
          fontSize: "1.2rem",
          fontWeight: "bold",
        }}
      >
        {header}
      </Typography>
      <Typography
        sx={{
          fontSize: "1.2rem",
        }}
      >
        {info}
      </Typography>
    </Box>
  );
}
