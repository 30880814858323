import { Box, Button, Card, Typography } from "@mui/material";
import {
  Catagory,
  CatagoryButtons as CatagoryButtonsType,
  CatagoryIcon,
} from "../../../types";
import { styled } from "@mui/material/styles";
import { ReactComponent as DesigningFig } from "../../../assets/icons/WorkshopApp_Designing.svg";
import { ReactComponent as EngineeringFig } from "../../../assets/icons/WorkshopApp_Engineering.svg";
import { ReactComponent as GeneralFig } from "../../../assets/icons/WorkshopApp_General.svg";
import { ReactComponent as ManagementFig } from "../../../assets/icons/WorkshopApp_Management.svg";
import { ReactComponent as MyCoursesFig } from "../../../assets/icons/WorkshopApp_MyCourses.svg";
import { ReactComponent as QualityFig } from "../../../assets/icons/WorkshopApp_Quality.svg";
import "../../../styles/Filter.css";

// List of catagories with their respective.
export const catagories: Catagory[] = [
  { name: "Engineering", icon: EngineeringFig },
  { name: "Design", icon: DesigningFig },
  { name: "Quality", icon: QualityFig },
  { name: "Project Management", icon: ManagementFig },
  { name: "General", icon: GeneralFig },
  { name: "My courses", icon: MyCoursesFig },
];

// Styling for catagory button.
const CatagoryButton = styled<any>(Button)(
  ({ theme, catagoryName, selectedFilter }) => ({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxShadow:
      selectedFilter === catagoryName
        ? "inset 0px 0px 0px 2px var(--twd_aqua)"
        : "none",
    padding: "2px",
    // borderColor: "var(--twd_aqua)",
    borderRadius: "12px",
    "&:hover": {
      backgroundColor: "#e9e9e9",
      opacity: 0.75,
    },
  })
);

/** HTML catagoryBox component for every catagory.
 *
 * @param {string} selectedFilter - Name of the catagory filter button that is selected.
 * @param {(catagoryName: string) => void} CatagoryButtonClicked - Function that is called when the catagory filter button that is clicked.
 * @returns HTML Box containing all the CatagoryBox components for every catagory.
 */
export function CatagoryButtons({
  selectedFilter,
  CatagoryButtonClicked,
}: CatagoryButtonsType) {
  return (
    <Box className={"categoryBox"}>
      {catagories.map((catagory: Catagory) => {
        const catagoryName: string = catagory.name;
        const CatagoryIcon: CatagoryIcon = catagory.icon;
        return (
          <Card className={"categoryButtonBox"}>
            <CatagoryButton
              catagoryName={catagoryName}
              selectedFilter={selectedFilter}
              onClick={() => CatagoryButtonClicked(catagoryName)}
            >
              <Box height="50%">
                <CatagoryIcon className={"categoryIcon"} />
              </Box>
              <Box height="50%" display="flex" alignItems="center">
                <Typography className={"categoryTitle"}>
                  {catagory.name}
                </Typography>
              </Box>
            </CatagoryButton>
          </Card>
        );
      })}
    </Box>
  );
}
