import React, { useState } from 'react';

import "../../styles/WorkshopList.css";
import EnrolledFig from "../../assets/icons/WorkshopApp_Favicon.svg";
import { Course, WorkshopCard as WorkshopCardType } from "../../types";
import { Card, Typography, CardActionArea } from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { format } from "date-fns";
import { useContext } from "react";
import { AccountProperties, AccountPropertiesContext } from "../SSO/SignIn";
import SchoolIcon from '@mui/icons-material/School';
import { Event } from "../../types";
import adminUsers from '../../admin_rights_users.json';


const isAttendeeConfirmed = (event: Event, Initials: any, userEmail: any): boolean => {
  const isHost = (event: Event) => {
    return event && (Initials === event.presenter || adminUsers.some((user:any) => userEmail === user.userEmail));
  };

  // If the current user is not the host event or admin no need to check if attendees are confirmed
  if (!isHost(event)) {
    return true;
  }

  // If the current user is the event host or the admin, proceed with the existing logic check if there is at least one attendee confirmed
  const teacherEventAttendees = event.teacherEventAttendees;
  return Object.values(teacherEventAttendees).some((workshop:any) =>
    workshop.attendees.some((attendee:any) => attendee.presence)
  );
};


// Styling for the CardButton of the workshops,
// that adds a border based on if the workshop is selected or not.
const WorkshopCardButton = styled(CardActionArea)<any>(({ selected }) => ({
  border: selected ? "2px solid " : "",
  borderColor: selected ? "var(--twd_aqua)" : "",
}));


/** HTML component for the workshop Cards in the WorkshopList.
 *
 * @param {CourseEvent} courseEvent - The courseEvent object containing the course and all its corresponding events.
 * @param {string} selectedCourseCode - The CourseCode of the selected courseEvent.
 * @param {(code: string) => void} ClickedOnCourse - Function that is called when the WorkshopCard is clicked on.
 * @returns HTML Card component with the catagory icon, course title and the first upcoming event date.
 */
export function WorkshopCard({
  courseEvent,
  selectedCourseCode,
  ClickedOnCourse,
}: WorkshopCardType) {
  // Get the course object from the courseEvent.
  const course: Course = courseEvent.course;

  const { userInfo } = useContext(AccountPropertiesContext) as AccountProperties;
  const { Initials, userEmail } = userInfo || {};
  // check if the user is the host of atleast one event in the course of if the user has admin rights
  const isUserHost = [
    ...courseEvent.events,
    ...courseEvent.userPastEvents
  ].some((event) => Initials === event.presenter || adminUsers.some((user: any) => userEmail === user.userEmail));


  const isAnyAttendeeConfirmedForEvent = [
    ...courseEvent.events,
    ...courseEvent.userPastEvents
  ].every(event => isAttendeeConfirmed(event, Initials, userEmail));
  

  // Get a formatted date of the first event if the course has events, otherwise return 'TBC'
  const firstEventDate: string =
    courseEvent.events.length > 0
      ? format(new Date(courseEvent.events[0].workshopDate), "dd-MM-yyyy")
      : "TBD";
  // Check if the course has any events for which the user has enrolled for.
  const isEnrolled: boolean = courseEvent.events.some(
    (event: any) => event.isEnrolled
  );

  // State to manage the tooltip visibility
  const [tooltipText, setTooltipText] = useState(''); // Set the initial text
  // Check if the user has attended the courses in the past.
  const userHasPastEvents = courseEvent.userPastEvents.some((pastEvent) => pastEvent.isEnrolled);
  
 
  return (
    <Card className={"workshopCard"}>
      <WorkshopCardButton
        className={"workshopCardButton"}
        onClick={() => {
          ClickedOnCourse(course.code);
        }}
        selected={selectedCourseCode === course.code}
        
      >
        {/* When the course has a catagory, add the catagory icon to the card otherwise add a empty div with the same size. */}
        {courseEvent.catagoryIcon ? (
          <courseEvent.catagoryIcon className={"workshopCardIcon"} />
        ) : (
          <div className={"workshopCardIcoEmpty"}></div>
        )}
        <Typography className={"workshopCardTitle"}>
          {course.workshopTitle}
        </Typography>
        <Typography className={"workshopCardDate"}>{firstEventDate}</Typography>
        {/* When the user is enrolled for at least one event in this course, add the enrolled icon. 
        If the user was enrolled for any workshops in the past add the checkCircleIcon */}
        {isUserHost ? (
          <div>
            <SchoolIcon
            className={`${
              isAnyAttendeeConfirmedForEvent
                ? 'workshopCardHostingIconBlue'
                : 'workshopCardHostingIconRed'
            }`}
            onMouseEnter={() => {
              setTooltipText(
                isAnyAttendeeConfirmedForEvent
                  ? 'Workshop participants confirmed.'
                  : 'At least one workshop event attendees needs to be confirmed.'
              );
            }}
            onMouseLeave={() => {
              setTooltipText(''); // Clear the tooltip text
            }}
              
            />
            <div className="icon-container">
            <span className={`tooltip ${tooltipText && 'visible'}` }>
                {tooltipText}
            </span>
            </div>
            
          </div>
        ) : isEnrolled ? (
          <img
            className={"workshopCardEnrolledIcon"}
            src={EnrolledFig}
            alt=""
          />
        ) : (
          userHasPastEvents && !isUserHost && (
            <CheckCircleIcon className={"workshopCardEnrolledIcon"} />
          )
        )}
      </WorkshopCardButton>
    </Card>
  );
}
