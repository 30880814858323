import { Box, Typography } from "@mui/material";
import { Filter as FilterType } from "../../../types";

import { SearchBar } from "./Search";
import { CatagoryButtons } from "./CatagoryButtons";
import "../../../styles/Filter.css";

/** Filter component containing the SearchBar and the CatagoryButtons.
 *
 * @param {string} selectedFilter - Name of the catagory filter button that is selected.
 * @param {(event: React.ChangeEvent<HTMLInputElement>) => void} ChangeSearchInput - Function which is called when the search input is changed.
 * @param {(catagoryName: string) => void} CatagoryButtonClicked - Function that is called when the catagory filter button that is clicked.
 * @returns HTML Box component with the SearchBar and the CatagoryButtons components.
 */
export function Filter({
  selectedFilter,
  ChangeSearchInput,
  CatagoryButtonClicked,
}: FilterType) {
  return (
    <Box className={"filterBox"}>
      <SearchBar ChangeSearchInput={ChangeSearchInput} />
      <CatagoryButtons
        selectedFilter={selectedFilter}
        CatagoryButtonClicked={CatagoryButtonClicked}
      />
    </Box>
  );
}
