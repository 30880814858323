import { confirmAttendee } from "../../services/AfasConnectors";
import React, { useState, useEffect } from 'react';
import { GetWorkshopAttendees } from "../../services/AfasConnectors";
import {AttendeesModal as AttendeesModalType} from "../../types";
import {Attendee} from "../../types";
import { styled } from "@mui/material/styles";
import {
  Box,
  Checkbox, 
  Button, 
  FormControlLabel,
  Typography,
  CircularProgress
} from "@mui/material";
import { format } from "date-fns";
import "../../styles/AttendeesModal.css";

const ConfirmAttendeesButton = styled(Button)<any>(({ theme, isUserHost }) => ({
  color: "white",
  backgroundColor: isUserHost ? "var(--twd_red)" : "var(--twd_navy)",
  margin: "3px",
  borderRadius: "12px",
  width: "10%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "none",
  "&:hover": {
    backgroundColor: isUserHost ? "var(--twd_red)" : "var(--twd_aqua)",
    transform: "scale(0.97)",
  },
  "&:disabled": {
    backgroundColor: "var(--twd_grey)",
    transform: "scale(0.97)",
  },
}));


const CloseAttendeesModal = styled(Button)<any>(() => ({
  color: "white",
  backgroundColor: "var(--twd_red)",
  margin: "3px",
  borderRadius: "12px",
  width: "10%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "var(--twd_red)",
    transform: "scale(0.97)",
  },
  "&:disabled": {
    backgroundColor: "var(--twd_grey)",
    transform: "scale(0.97)",
  },
}));

// Styled Typography for the headers of the event table
const StyledEventheader = styled(Typography)(({ theme }) => ({
  width: "20%",
  textAlign: "left",
  fontSize: "1.2rem",
  fontWeight: "bold",
}));
// Styled Typography of the rows of the event table.
const StyledEventrow = styled(Typography)(({ theme }) => ({
  width: "20%",
  textAlign: "left",
  fontSize: "1.2rem",
  color: "black",
  "&:disabled": {
    color: "var(--twd_grey)",
  },
}));


export function AttendeesModal({
  selectedCourse,
  setSelectedCourse,
  AttendeesConfirmed,
  selectedButton,
  onClose,
}: AttendeesModalType){
const [isLoading, setIsLoading] = useState<boolean>(false);

const combinedTeacherEventAttendees = {
  ...selectedCourse.events.reduce((acc, event) => {
    const key = Object.keys(event.teacherEventAttendees)[0];
    acc[key] = event.teacherEventAttendees[key];
    return acc;
  }, {}),
  ...selectedCourse.userPastEvents.reduce((acc, event) => {
    const key = Object.keys(event.teacherEventAttendees)[0];
    acc[key] = event.teacherEventAttendees[key];
    return acc;
  }, {}),
};

const isWorkshopHasAttendees = selectedButton.workshopId in combinedTeacherEventAttendees;

  // const eventAttendees = combinedTeacherEventAttendees[selectedButton.workshopId].attendees
const eventAttendees = combinedTeacherEventAttendees[selectedButton.workshopId]?.attendees || [];



// const eventAttendees = combinedTeacherEventAttendees[selectedButton.workshopId].attendees


  useEffect(() => {
    // Update the initial state of checkedAttendees based on attendee.presence
    const initialCheckedAttendees = Object.fromEntries(
        eventAttendees.map((attendee: {
          userExternalID: string;
          presence: boolean
        }) => [attendee.userExternalID, attendee.presence || false])
    );

    setCheckedAttendees(initialCheckedAttendees);
  }, [eventAttendees]);

  const [checkedAttendees, setCheckedAttendees] = useState<Record<string, boolean>>({});
  const [selectAll, setSelectAll] = useState(false);
  const [attendeesLoaded, setAttendeesLoaded] = useState(false);

  useEffect(() => {
    // set the confirm button to disabled for the first 0.5 seconds when so that the list of attendees are loaded first
    setTimeout(() => {
      setAttendeesLoaded(true);
    }, 500);
  }, []);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, userExternalID: string | undefined) => {
    const updatedCheckedAttendees = {...checkedAttendees, [userExternalID!]: event.target.checked};
    setCheckedAttendees(updatedCheckedAttendees);
    const allVisibleCheckboxesChecked = eventAttendees.every(
        (attendee: Attendee) => updatedCheckedAttendees[attendee.userExternalID]
    );
    setSelectAll(allVisibleCheckboxesChecked);
  };

  const handleSelectAllCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectAllValue = event.target.checked;

    // Update the checked state for all attendees
    const updatedCheckedAttendees: Record<string, boolean> = {};
    eventAttendees.forEach((attendee: Attendee) => {
      updatedCheckedAttendees[attendee.userExternalID] = selectAllValue;
    });

    setCheckedAttendees(updatedCheckedAttendees);
    setSelectAll(selectAllValue);
  };


  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      const initialCheckedAttendees: Record<string, boolean> = Object.fromEntries(
          eventAttendees.map((attendee: Attendee) => [attendee.userExternalID, attendee.presence || false])
      );

      const attendeesToConfirm = eventAttendees.map((attendee: Attendee) => ({
        ...attendee,
        toConfirm: checkedAttendees[attendee.userExternalID] !== undefined
            ? checkedAttendees[attendee.userExternalID]
            : initialCheckedAttendees[attendee.userExternalID] || false,
      }));

      // Confirm attendees
      await confirmAttendee(attendeesToConfirm, selectedButton.workshopId);

      // Fetch updated data from AFAS
      const updatedAttendees = await GetWorkshopAttendees(selectedButton.workshopId);

      // Update the selectedCourse state with the new data
      const updatedTeacherEventAttendees = {
        ...selectedCourse.events.find((event) => event.workshopId === selectedButton.workshopId)?.teacherEventAttendees,
        [selectedButton.workshopId]: {
          ...selectedCourse.events.find((event) => event.workshopId === selectedButton.workshopId)?.teacherEventAttendees?.[selectedButton.workshopId],
          attendees: updatedAttendees,
        },
      };

      const updatedSelectedCourse = {
        ...selectedCourse,
        events: selectedCourse.events.map((event) => {
          if (event.workshopId === selectedButton.workshopId) {
            return {
              ...event,
              teacherEventAttendees: updatedTeacherEventAttendees,
            };
          }
          return event;
        }),
      };

      // Set the updated state
      AttendeesConfirmed(selectedButton.code)
      setSelectedCourse(updatedSelectedCourse);

      // Close the modal
      onClose();
      setIsLoading(false);
    } catch (error) {
      console.error('Error handling confirmation:', error);
    }
  };

  const parsedDate = format(new Date(selectedButton.workshopDate), "dd.MM.yyyy");
  const startTimeObject = new Date(
    `1970-01-01T${selectedButton.workshopStarttime}`
  );
  const parsedStartTime = `${startTimeObject
    .getHours()
    .toString()
    .padStart(2, "0")}:${startTimeObject
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;
  const endTimeObject = new Date(`1970-01-01T${selectedButton.workshopEndtime}`);
  const parsedEndTime = `${endTimeObject
    .getHours()
    .toString()
    .padStart(2, "0")}:${endTimeObject
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;



    return (
      <div className="attendees-modal non-blocking-modal attendees-box">
        <div className={"EventDetails"}>
         <Box className={"EventInfoHeaders"}>
           <StyledEventheader>Date</StyledEventheader>
           <StyledEventheader>Time (NL)</StyledEventheader>
           <StyledEventheader>Attendees</StyledEventheader>
           <StyledEventheader>Host</StyledEventheader>
           <StyledEventheader>Location</StyledEventheader>
           <Box width="5%"></Box>
         </Box>
        <Box className={"EventInfoRows"}>
           <Box
             className={"EventInfoButton"}
           >
             <StyledEventrow>{parsedDate}</StyledEventrow>
             <StyledEventrow>
               {parsedStartTime} - {parsedEndTime}
             </StyledEventrow>
             <StyledEventrow>
               {selectedButton.attendeesCount || 0} / {selectedButton.attendeesMax}
             </StyledEventrow>
             <StyledEventrow>{selectedButton.presenter}</StyledEventrow>
             <StyledEventrow>{selectedButton.workshopLocation}</StyledEventrow>
             <Box
               width="5%"
               display="flex"
               alignItems="center"
               justifyContent="center"
             >
             </Box>
           </Box>
         </Box>
       </div>
        
    
          {isWorkshopHasAttendees ? (
            <div className="attendance-details">
            <div className="attendees-header">
              <h3>Attendance</h3>
              <div className="selectAll-item">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectAll}
                      onChange={handleSelectAllCheckboxChange}
                    />
                  }
                  label="Select All"
                />
              </div>
            </div>
            <div className="attendees-container">
              {eventAttendees.map((attendee: Attendee, index: any) => (
                <div key={index} className="checkbox-item">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={attendee.userExternalID}
                        defaultChecked={attendee.presence === true}
                        checked={checkedAttendees[attendee.userExternalID] || false}
                        onChange={(e) =>
                          handleCheckboxChange(e, attendee.userExternalID)
                        }
                      />
                    }
                    label={
                      typeof attendee === 'object'
                        ? (attendee as any).participantName
                        : attendee
                    }
                  />
                </div>
              ))}
            </div>
            </div>
            
            
            
          ) : (
            <div className="attendance-details">
              <div className="attendees-header">
                <h3>Attendance</h3>
              </div>
              <div className="attendees-container">
              <h5>No participants have registered for this workshop event yet.</h5>
              </div>
            </div>
          )}
          

          <div className="MuiBox-root css-3ppzze">
            <CloseAttendeesModal
                onClick={onClose}
              >
                <Typography sx={{ fontSize: "0.85rem", fontWeight: "bold" }}>
                  Back
                </Typography>
            </CloseAttendeesModal>
            {isLoading ? (
                <CircularProgress
                  sx={{
                    color: "var(--twd_aqua)",
                    marginRight: "20px",
                    width: "10%",
                  }}
                />
                ) : (
            <ConfirmAttendeesButton
                onClick={handleConfirm}
                disabled={!attendeesLoaded || !isWorkshopHasAttendees}

              >
                <Typography sx={{ fontSize: "0.85rem", fontWeight: "bold" }}>
                  Confirm
                </Typography>
            </ConfirmAttendeesButton>
            )}
          </div>
        
      </div>
    );
}

export default AttendeesModal;
