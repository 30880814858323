import { Box, InputBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { SearchBar as SearchBarType } from "../../../types";
import "../../../styles/Filter.css";

// Styling for the div containing the search icon.
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

// Styling for the input field of the search bar.
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  height: "100%",
  width: "100%",
  paddingLeft: `calc(1em + ${theme.spacing(1)})`,
}));

/** HTML component for the Search bar.
 *
 * @param {(event: React.ChangeEvent<HTMLInputElement>) => void} ChangeSearchInput - Function which is called when the search input is changed.
 * @returns HTML Box containing the SearBar styling and input box.
 */
export function SearchBar({ ChangeSearchInput }: SearchBarType) {
  return (
    <Box width="100%" marginBottom="2%">
      <div className={"search"}>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          onChange={ChangeSearchInput}
        />
        <SearchIconWrapper>
          <SearchIcon sx={{ color: "var(--twd_grey)" }} />
        </SearchIconWrapper>
      </div>
    </Box>
  );
}
