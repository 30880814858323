import { Box, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { sortOnDate } from "../../utils/courseUtils";
import { CourseEvent, WorkshopList as WorkshopListType } from "../../types";
import { WorkshopCard } from "./WorkshopCard";
import "../../styles/WorkshopList.css";

/** HTML component for the full workshopList containing all the WorkshopCards for upcoming
 * and not planned in workshops.
 *
 * @param {CourseEvent[]} courseEventList - List of all courses with their corresponding events.
 * @param {CourseEvent} selectedCourse - The course that is selected.
 * @param {(code: string) => void} ClickedOnCourse - Function that is called when a WorkshopCard is clicked on.
 * @returns HTML component containing all future and past WorkshopCards.
 */
export function WorkshopList({
  courseEventList,
  selectedCourse,
  ClickedOnCourse,
}: WorkshopListType) {
  // State for the courses with a date.
  const [coursesWithDates, setCoursesWithDates] = useState<CourseEvent[]>([]);
  // State for the courses without a date.
  const [coursesWithNoDates, setCoursesWithNoDates] = useState<CourseEvent[]>(
    []
  );

  useEffect(() => {
    const hasDates: CourseEvent[] = [];
    const hasNoDates: CourseEvent[] = [];

    courseEventList.forEach((courseEvent: CourseEvent) => {
      // If course has events, add it to the hasDates list.
      if (courseEvent.events.length > 0) {
        hasDates.push(courseEvent);
      } // If course has no events, add it to the hasNoDates list.
      else {
        hasNoDates.push(courseEvent);
      }
    });
    // For all the courses that have events, sort them based on Date.
    setCoursesWithDates(
      hasDates.sort((courseEventA: CourseEvent, courseEventB: CourseEvent) => {
        return sortOnDate(courseEventA, courseEventB);
      })
    );
    setCoursesWithNoDates(hasNoDates);
  }, [courseEventList]);

  return (
    <>
      <Box className={"workshopBox"}>
        {coursesWithDates.map((updatedCourseEventList: CourseEvent) => {
          return (
            <WorkshopCard
              courseEvent={updatedCourseEventList}
              selectedCourse={selectedCourse}
              selectedCourseCode={
                selectedCourse ? selectedCourse.courseCode : ""
              }
              ClickedOnCourse={ClickedOnCourse}
            />
          );
        })}
        {coursesWithNoDates.length > 0 && (
          <Divider
            variant="middle"
            sx={{
              width: "40%",
              marginLeft: "25%",
              marginRight: "25%",
              color: "#707070;",
              borderBottomWidth: 5,
              marginBottom: "2%",
            }}
          />
        )}
        {coursesWithNoDates.map((updatedCourseEventList: CourseEvent) => {
          return (
            <WorkshopCard
              courseEvent={updatedCourseEventList}
              selectedCourse={selectedCourse}
              selectedCourseCode={
                selectedCourse ? selectedCourse.courseCode : ""
              }
              ClickedOnCourse={ClickedOnCourse}
            />
          );
        })}
      </Box>
    </>
  );
}
