import { Box, Typography } from "@mui/material";
import iconSearch from "../../assets/icons/Search BG Icon.svg";

export function WorkshopInfoPlaceHolder() {
  return (
    <Box
      height="90%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <img src={iconSearch} alt="?" style={{ width: "50%", height: "50%" }} />
      <Typography
        align="left"
        variant="h6"
        component="div"
        sx={{
          fontSize: "1rem",
        }}
      >
        Select a workshop for more information
      </Typography>
    </Box>
  );
}
