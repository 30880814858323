import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Popper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Event, Attendee } from "../../types";
import { format } from "date-fns";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useContext, useState } from "react";
import EnrolledFig from "../../assets/icons/WorkshopApp_Favicon.svg";
import SchoolIcon from '@mui/icons-material/School';
import "../../styles/WorkshopInfo.css";
import { AccountProperties, AccountPropertiesContext } from "../SSO/SignIn";
import adminUsers from '../../admin_rights_users.json';


// Styled Typography for the headers of the event table
const StyledEventheader = styled(Typography)(({ theme }) => ({
  width: "20%",
  textAlign: "left",
  fontSize: "1.2rem",
  fontWeight: "bold",
}));

// Styled Typography of the rows of the event table.
const StyledEventrow = styled(Typography)(({ theme }) => ({
  width: "20%",
  textAlign: "left",
  fontSize: "1.2rem",
  color: "black",
  "&:disabled": {
    color: "var(--twd_grey)",
  },
}));

export function EventTable({
  events,
  selectedButton,
  setSelectedButton,
  isPastEvents,
  disabledEvents,
}: any) {
  
  const [openPopper, setOpenPopper] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const clickOnEvent = (event: any) => {
    setSelectedButton(event);
  };
  const { userInfo } = useContext(AccountPropertiesContext) as AccountProperties;
  const { Initials, userEmail } = userInfo || {};

  const handleHover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenPopper(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenPopper(false);
  };
  const [tooltipText, setTooltipText] = useState(''); // Set the initial text
  const [toolTipId, setToolTipId] = useState<any>(null)

  const hasEnrolledEvent = events.some((event:any) => event.isEnrolled);
  const isEventHost = events.some((event:any) => event.presenter === Initials || adminUsers.some((user:any) => userEmail === user.userEmail));

  if (isPastEvents && !hasEnrolledEvent && !isEventHost) {
    // This if statement makes sure that the event table header is not displayed.
    return null;
  }

  return (
    <>
      <Box className={"WorkshopTimeInfoHeaders"}>
        <StyledEventheader>Date</StyledEventheader>
        <StyledEventheader>Time (NL)</StyledEventheader>
        <StyledEventheader>Attendees</StyledEventheader>
        <StyledEventheader>Host</StyledEventheader>
        <StyledEventheader>Location</StyledEventheader>
        <Box width="5%"></Box>
      </Box>
      {events.map((event: Event) => {
        const parsedDate = format(new Date(event.workshopDate), "dd.MM.yyyy");
        const startTimeObject = new Date(
          `1970-01-01T${event.workshopStarttime}`
        );
        const parsedStartTime = `${startTimeObject
          .getHours()
          .toString()
          .padStart(2, "0")}:${startTimeObject
          .getMinutes()
          .toString()
          .padStart(2, "0")}`;
        const endTimeObject = new Date(`1970-01-01T${event.workshopEndtime}`);
        const parsedEndTime = `${endTimeObject
          .getHours()
          .toString()
          .padStart(2, "0")}:${endTimeObject
          .getMinutes()
          .toString()
          .padStart(2, "0")}`;

        // Read the JSON file
        const isUserHost =  Initials === event.presenter || adminUsers.some((user:any) => userEmail === user.userEmail);
        // Check if at least one attendee has presence set to true
        const isAttendeeConfirmedForEvent = Object.values(event.teacherEventAttendees || {}).some(
          (attendee: any) => attendee.attendees.some((a: Attendee) => a.presence)
        );
        
        const isEventFull =
          event.attendeesCount === event.attendeesMax && !event.isEnrolled && !isUserHost;
        const isAvailableOutlook = disabledEvents.find(
          (disabledEvent: any) => disabledEvent.eventId === event.workshopId
        );

        if(!isUserHost && !event.isEnrolled && isPastEvents){
          return null;
        }
        
        return (
          <Box className={"WorkshopTimeInfoRows"}>
            <Button
              className={"WorkshopTimeInfoButton"}
              onClick={() => clickOnEvent(event)}
              disabled={isEventFull || (isPastEvents && !isUserHost )}
              sx={{
                border:
                  selectedButton.workshopId === event.workshopId
                    ? "2px solid"
                    : "",
              }}
            >
              <StyledEventrow>{parsedDate}</StyledEventrow>
              <StyledEventrow>
                {parsedStartTime} - {parsedEndTime}
              </StyledEventrow>
              <StyledEventrow>
                {event.attendeesCount || 0} / {event.attendeesMax}
              </StyledEventrow>
              <StyledEventrow>{event.presenter}</StyledEventrow>
              <StyledEventrow>{event.workshopLocation}</StyledEventrow>
              <Box
                width="5%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {isAvailableOutlook && !isAvailableOutlook.available ? (
                  <IconButton
                    onMouseOver={(mouseEvent: any) => {
                      handleHover(mouseEvent);
                    }}
                    onMouseLeave={() => {
                      handleClose();
                    }}
                  >
                    <WarningAmberIcon
                      sx={{
                        color: "var(--twd_red)",
                      }}
                    />
                  </IconButton>
                 ) : isUserHost ? (
                  <div>
                    <SchoolIcon
                    className={`${
                      isAttendeeConfirmedForEvent
                        ? 'workshopEventHostingIconBlue'
                        : 'workshopEventHostingIconRed'
                    }`}
                    onMouseEnter={() => {
                      setToolTipId(event.workshopId);
                      setTooltipText(
                        isAttendeeConfirmedForEvent
                          ? 'Workshop participants confirmed.'
                          : 'Workshop attendees needs to be confirmed'
                      );
                    }}
                    onMouseLeave={() => {
                      setToolTipId(null);
                      setTooltipText(''); // Clear the tooltip text
                    }}
                      
                    />
                    <div className="icon-container">
                    <span className={`tooltip ${toolTipId === event.workshopId && tooltipText && 'visible'}` }>
                        {tooltipText}
                    </span>
                    </div>
                    
                  </div>
                  ) : event.isEnrolled && !isPastEvents? (
                  <img src={EnrolledFig} alt="" style={{ width: "60%" }}></img>
                 ) :  (
                  isPastEvents && (
                    <CheckCircleIcon
                      sx={{
                        color: "var(--twd_aqua)",
                      }}
                    />
                  )
                )}
              </Box>
            </Button>
          </Box>
        );
      })}
      <Popper
        open={openPopper}
        anchorEl={anchorEl}
        onMouseOver={() => setOpenPopper(true)}
        onMouseLeave={() => setOpenPopper(false)}
        placement="left-start"
        sx={{ zIndex: 2 }}
      >
        <Card>
          <CardContent>
            <Typography>
              Cannot apply for this event, because calendar item missing.
            </Typography>
            <Typography>Please contact HR.</Typography>
          </CardContent>
        </Card>
      </Popper>
    </>
  );
}
