import AppBar from "@mui/material/AppBar";
import {
  Box,
  IconButton,
  InputBase,
  ThemeProvider,
  Toolbar,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import { twdTheme } from "../../styles/CustomMaterialTheme";
import TWDLogo from "../../assets/TWD_Logo_Diap_RGB.svg";
import "../../styles/NavBar.css";
import { useContext, useState } from "react";
import { AccountProperties, AccountPropertiesContext } from "../SSO/SignIn";
import { QuestionButton } from "./QuestionButton";
import { ProfileButton } from "./ProfileButton";

function LinkToSharePoint() {
  window.location.href = "https://twdbv.sharepoint.com/";
}

function TWDLogoButton() {
  return (
    <Box className="twdlogobox">
      <IconButton
        className="twdlogobutton"
        size="small"
        edge="start"
        aria-label="menu"
        onClick={() => LinkToSharePoint()}
      >
        <img className="TWDLogo" src={TWDLogo} alt=""></img>
      </IconButton>
    </Box>
  );
}

function AppTitle() {
  return (
    <Typography
      className="title"
      align="left"
      variant="h6"
      noWrap
      component="div"
      sx={{ display: { xs: "none", sm: "block" } }}
    >
      Workshops
    </Typography>
  );
}

export function NavBar() {
  // AccountProperties Context from the SignIn.tsx
  const { profileName, profilePicture } = useContext(
    AccountPropertiesContext
  ) as AccountProperties;

  return (
    <AppBar className="top-nav">
      <Toolbar className="navToolbar">
        <TWDLogoButton />
        <AppTitle />
        <Box
          sx={{
            width: "20%",
            height: "100%",
            display: "flex",
            justifyContent: "right",
            alignItems: "right",
          }}
        ></Box>
        <Box className="profilebox">
          <QuestionButton />
          <ProfileButton
            profileName={profileName}
            profilePicture={profilePicture}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
