import { Client } from "@microsoft/microsoft-graph-client";
import { CourseEvent, Event } from "../types";

const userId = "b1610ba1-30a8-44f8-b2d7-aabc2410c60a";


const getOutlookEventDetailsRequest = async (graphClient: any, userId: string, eventName: string, eventDate: string) => {
  try {
      const encodedEventName = encodeURIComponent(eventName);

    // in the event name has an ampersand then it will be in the query string which causes an issue then we should use the URL-encoded value %26
    return await graphClient
      .api(`/users/${userId}/events?$filter=subject eq '${encodedEventName}' and start/dateTime eq '${eventDate}'`)
      .headers({ Prefer: 'outlook.timezone="Central Europe Standard Time"' })
      .get();
  } catch (error) {
    return null;
  }
};

export function getDateAndTime(event: any) {
  return event.workshopDate.split("T")[0] + "T" + event.workshopStarttime + "Z";
}

export async function getOutlookCourse(
  accesToken: string,
  courseEvent: CourseEvent,
  event: Event
) {
  const graphClient = Client.init({
    authProvider: async (done) => {
      const token = accesToken;
      // Get the token from the auth service
      if (token) {
        done(null, token);
      } else {
        done("Could not get an access token", null);
      }
    },
  });

  const eventName = courseEvent.course.workshopTitle;
  const eventDate = getDateAndTime(event);
  try {
    const getOutlookEventDetails = await getOutlookEventDetailsRequest(graphClient, userId, eventName, eventDate);
    console.log(getOutlookEventDetails);
    return getOutlookEventDetails;
  } catch (error) {
    return null;
  }
}

export async function SendEventInvite(
  accesToken: string,
  userInfo: any,
  selectedEvent: any,
  workshopTitle: string
) {
  const graphClient = Client.init({
    authProvider: async (done) => {
      const token = accesToken;
      // Get the token from the auth service
      if (token) {
        done(null, token);
      } else {
        done("Could not get an access token", null);
      }
    },
  });
  const userEmail = userInfo.userEmail;
  const eventName = workshopTitle;
  const eventDate = getDateAndTime(selectedEvent);
  const getOutlookEventDetails = await getOutlookEventDetailsRequest(graphClient, userId, eventName, eventDate);
  const getOutlookEventDetail = getOutlookEventDetails.value[0];
  const meetingAttendees = getOutlookEventDetail.attendees;
  const newAttendee = {
    emailAddress: {
      address: userEmail,
    },
    type: "required",
  };
  meetingAttendees.push(newAttendee);

  const JsonAttendees = {
    attendees: meetingAttendees,
  };
  const outlookEventId = getOutlookEventDetail.id;
  try {
    const response = await graphClient
      .api(`/users/${userId}/events/${outlookEventId}`)
      .update(JsonAttendees);
    return response;
  } catch (error) {
    return "";
  }
}

export async function RemoveCalendarEvent(
  accesToken: string,
  userInfo: any,
  selectedEvent: any,
  workshopTitle: string
) {
  const graphClient = Client.init({
    authProvider: async (done) => {
      const token = accesToken;
      // Get the token from the auth service
      if (token) {
        done(null, token);
      } else {
        done("Could not get an access token", null);
      }
    },
  });
  const userEmail = userInfo.userEmail;
  const eventName = workshopTitle;
  const eventDate = getDateAndTime(selectedEvent);
  const getOutlookEventDetails = await getOutlookEventDetailsRequest(graphClient, userId, eventName, eventDate);
  const getOutlookEventDetail = getOutlookEventDetails.value[0];
  const meetingAttendees = getOutlookEventDetail.attendees;

  const newMeetingAttendees = meetingAttendees.filter(
    (attendee: any) => attendee.emailAddress.address !== userEmail
  );
  const JsonAttendees = {
    attendees: newMeetingAttendees,
  };
  const outlookEventId = getOutlookEventDetail.id;
  try {
    const response = await graphClient
      .api(`/users/${userId}/events/${outlookEventId}`)
      .update(JsonAttendees);
    return response;
  } catch (error) {
    return "";
  }
}
