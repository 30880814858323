import { NavBar } from "./components/NavBar/NavBar";
import { useContext, useEffect, useState } from "react";
import {
  GetAllCourses,
  GetAllEmployeeEvents,
  GetAllEvents,
  GetAllHostAttendees
} from "./services/AfasConnectors";
import {
  AccountProperties,
  AccountPropertiesContext,
} from "./components/SSO/SignIn";
import { Workshops } from "./components/Workshops";
import { Box, ThemeProvider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Course, Event, UserEvent, HostEvent } from "./types";
import { twdTheme } from "./styles/CustomMaterialTheme";
import "./App.css";

// Styling for the Box containing the whole app.
const AppBox = styled(Box)(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  overflow: "hidden",
  backgroundColor: "var(--twd_grey)",
}));

/** App component which loads in all necessary AFAS information and contains the Navbar and the workshop components.
 *
 * @returns HTML component box containing the NavBar and Workshop body.
 */
function App() {
  // AccountProperties Context from the SignIn.tsx
  const { userInfo } = useContext(
    AccountPropertiesContext
  ) as AccountProperties;
  // State for all the courses in AFAS.
  const [courses, setCourses] = useState<Course[]>([]);

  // State for all the events in AFAS.
  const [events, setEvents] = useState<Event[]>([]);

  // State for all events of the user.
  const [userEvents, setUserEvents] = useState<UserEvent[]>([]);

  // State when courses, events and userEvents are done loading.
  const [finishedLoading, setFinishedLoading] = useState<boolean>(false);
  // State to store teachers events
  const [teacherEvents, setTeacherEvents] = useState<HostEvent[]>([]);

  useEffect(() => {
    // Load in all the courses
    GetAllCourses().then((response) => {
      setCourses(response);
      // Load in all the events
      GetAllEvents().then((response) => {
        setEvents(response);
        GetAllEmployeeEvents(userInfo?.Initials).then((response) => {
          setUserEvents(response);
          // get teacher events
          GetAllHostAttendees().then((response) =>{
            setTeacherEvents(response);
            setFinishedLoading(true);
          })
        });
      });
    });
  }, []);

  // Function that reloads all the events and user Events.
  const ReloadEvents = async () => {
    const events = await GetAllEvents();
    setEvents(events);
    const userEvents = await GetAllEmployeeEvents(userInfo?.Initials);
    setUserEvents(userEvents);
    const eventsAttendees = await GetAllHostAttendees();
    setTeacherEvents(eventsAttendees);
    return [events, userEvents, eventsAttendees];
  };

  return (
    <>
      {finishedLoading && (
        <ThemeProvider theme={twdTheme}>
          <AppBox className={"app"}>
            <NavBar />
            <Workshops
              courses={courses}
              events={events}
              userEvents={userEvents}
              teacherEvents={teacherEvents}
              ReloadEvents={ReloadEvents}
            />
          </AppBox>
        </ThemeProvider>
      )}
    </>
  );
}

export default App;
