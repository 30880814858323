import { catagories } from "../components/LeftColumn/Filter/CatagoryButtons";
import { Course, Event, UserEvent, CourseEvent, Catagory, HostEvent } from "../types";
import adminUsers from '../admin_rights_users.json';




/** Gets the workshopDate and workshopTime from the event as creates a DateTime object.
 *
 * @param {Event} event - The event that you want the date and time of.
 * @returns DateTime object.
 */
export function getDateAndTime(event: Event) {
  return new Date(
    event.workshopDate.split("T")[0] + "T" + event.workshopStarttime
  ).getTime();
}

/** Filters the events on time and splits them up in upcoming and past events.
 *
 * @param {Event} events - The events to be splitted.
 * @returns Lists of upcoming and past events.
 */
export function FilterEventsOnTime(events: Event[]): [Event[], Event[]] {
  // Get the date and time of this moment.
  const currentDate = new Date().getTime();
  return events.reduce(
    ([upcoming, past]: [Event[], Event[]], elem: Event): [Event[], Event[]] => {
      // Combines the date and time.
      const dateAndTime = getDateAndTime(elem);
      // Compares the events date and time to the time right now.
      return dateAndTime > currentDate
        ? [[...upcoming, elem], past]
        : [upcoming, [...past, elem]];
    },
    [[], []]
  );
}

/** Creates a combined CourseEvent list from the courses, events and userEvents.
 *
 * @param {Course[]} courses - All the courses from AFAS.
 * @param {Event[]} events - All the events from AFAS.
 * @param {UserEvent[]} userEvents - All the events the user is enrolled for.
 * @param {UserEvent[]} teacherEvents - Events hosted by teacher.
 * @param {string} userInfo - logged-in user info.
 *
 * @returns a list of objects consisting of the courseCode, course object, the corresponding
 */
export function createCourseEventList(
  courses: Course[],
  events: Event[],
  userEvents: UserEvent[],
  teacherEvents: HostEvent[],
  userInfo: any
): CourseEvent[] {
  const courseEvents: CourseEvent[] = [];
  // Filter events into upcoming and past events.
  const [upcomingEvents, pastEvents] = FilterEventsOnTime(events);

  courses.forEach((course: Course) => {
    // Get all the upcoming events for this course.
    let thiscourseEvents = upcomingEvents.filter((event: Event) => {
      return event.code === course.code;
    });
    
    // For all the upcoming events of this course, check if user is enrolled for this course.
    thiscourseEvents = thiscourseEvents.map((thiscourseEvent: Event) => {
      const isEnrolled = userEvents.find((userEvent: UserEvent) => {
        return thiscourseEvent.workshopId === userEvent.eventId;
      });

      const attendeesByWorkshop: {
        [key: number]: {
          attendees: { participantName: string; userExternalID: string; userInternalID: string; presence: boolean }[];
        };
      } = {};
      
      teacherEvents.forEach(item => {
        const { participantName, workshopId, presenter, userExternalID, userInternalID, presence } = item;
      
        if (thiscourseEvent.workshopId === workshopId) {
          const workshopEntry = attendeesByWorkshop[workshopId];
      
          if (!workshopEntry) {
            attendeesByWorkshop[workshopId] = {
              attendees: [{ participantName, userExternalID, userInternalID, presence }],
            };
          } else {
            workshopEntry.attendees.push({ participantName, userExternalID, userInternalID, presence });
          }
        }
      });
      
      return { ...thiscourseEvent, isEnrolled: isEnrolled, teacherEventAttendees: attendeesByWorkshop};
    });
    // Get all past events for this course.
    const thisCoursePastEvents = pastEvents.filter((event: Event) => {
      return event.code === course.code;
    });
    const { Initials, userEmail } = userInfo || {};
    // For all the past events of this course, check if user was enrolled for this course.
    // const userPastEvents = thisCoursePastEvents.filter(
    //   (thisCoursePastEvent: Event) => {
    //     const userHasPastEvent = userEvents.find((userEvent: UserEvent) => {
    //       return thisCoursePastEvent.workshopId === userEvent.eventId || Initials === thisCoursePastEvent.presenter || adminUsers.some((user:any) => userEmail === user.userEmail);
    //     });
    //     return userHasPastEvent;
    //   }
    // );

    const userPastEvents = thisCoursePastEvents.map((thisCoursePastEvent: Event) => {
      const userHasPastEvent = userEvents.find((userEvent: UserEvent) => {
        return (
          thisCoursePastEvent.workshopId === userEvent.eventId ||
          Initials === thisCoursePastEvent.presenter ||
          adminUsers.some((user: any) => userEmail === user.userEmail)
        );
      });
    
      const teacherEventAttendees: {
        [key: number]: {
          attendees: { participantName: string; userExternalID: string; userInternalID: string; presence: boolean }[];
        };
      } = {};
    
      teacherEvents.forEach((item) => {
        const { participantName, workshopId, presenter, userExternalID, userInternalID, presence } = item;
    
        if (thisCoursePastEvent.workshopId === workshopId) {
          const workshopEntry = teacherEventAttendees[workshopId];
    
          if (!workshopEntry) {
            teacherEventAttendees[workshopId] = {
              attendees: [{ participantName, userExternalID, userInternalID, presence }],
            };
          } else {
            workshopEntry.attendees.push({ participantName, userExternalID, userInternalID, presence });
          }
        }
      });
    
      return { ...thisCoursePastEvent, isEnrolled: userHasPastEvent, teacherEventAttendees: teacherEventAttendees };
    });

    const catagoryIcon = catagories.find(
      (catagory: Catagory) => catagory.name === course.CourseGroup
    )?.icon;
    courseEvents.push({
      courseCode: course.code,
      course: course,
      events: thiscourseEvents,
      userPastEvents: userPastEvents || [],
      catagoryIcon: catagoryIcon || "",
    });
  });
  return courseEvents;
}

/** Filtes the items in the courseEventList based on which catagory buttons are clicked
 * and what is put in the searchbar.
 *
 * @param {CourseEvent[]} courseEventList - The courseEventList consisting of all the courses with corresponding events.
 * @param {string} selectedFilter - The name of the selected catagory filter option.
 * @param {string} filterQuery - The filter query from the searchBar.
 * @param {string} userInfo - logged-in user info.
 *
 * @returns
 */
export function FilterCourseEvents(
  courseEventList: CourseEvent[],
  selectedFilter: string,
  filterQuery: string,
  userInfo: any
) {
  let filteredCourseEvents: CourseEvent[] = courseEventList;
  
  
  // If selectedFilter is not empty string, filter the courseEventList based on the filter that is selected.
  if (selectedFilter !== "") {
    
    filteredCourseEvents = courseEventList.filter(
      (courseEvent: CourseEvent) => {
        // If `My courses` is selected, check all events of each course if the user is or was enrolled for that course.
        if (selectedFilter === "My courses") {

          const { Initials, userEmail, userExternalId } = userInfo || {};
          const isForCoursehosting = courseEvent.events.some((event) => Initials === event.presenter || adminUsers.some((user:any) => userEmail === user.userEmail));
          const isForPastCoursehosting = courseEvent.userPastEvents.some((event) => Initials === event.presenter || adminUsers.some((user:any) => userEmail === user.userEmail));

          const isForCourseEnrolled = courseEvent.events.some(
            (event: any) => event.isEnrolled
          );
          // Check if the user has any events he/she was enrolled for in the past.
          // const hasUserPastEvents = courseEvent.userPastEvents.length > 0;
          const hasUserPastEvents = !!courseEvent.userPastEvents &&
                                  courseEvent.userPastEvents.some(event =>
                                    !!event.teacherEventAttendees &&
                                    Object.values(event.teacherEventAttendees).some((attendee:any) =>
                                      !!attendee.attendees &&
                                      attendee.attendees.some((a:any)=> a.userExternalID === userExternalId)
                                    )
                                  );

          return isForCoursehosting || isForCourseEnrolled || isForPastCoursehosting || hasUserPastEvents;
          
        }
        return selectedFilter === courseEvent.course.CourseGroup;
      }
    );
  }
  // Filter the course if their titles include the filterQuery string from the searchbar.
  filteredCourseEvents = filteredCourseEvents.filter((courseEvent: any) =>
    courseEvent.course.workshopTitle.toLowerCase().includes(filterQuery)
  );
  return filteredCourseEvents;
}

/** Sort the courses based on the date of the first upcoming event.
 *
 * @param {CourseEvent} courseEventA - The first course to be compared on date.
 * @param courseEventB - The second course to be compared on date.
 * @returns negative or positive number based on the date comparison.
 */
export function sortOnDate(
  courseEventA: CourseEvent,
  courseEventB: CourseEvent
) {
  // Get the date + time object of the first event of courseA.
  const eventADate = getDateAndTime(courseEventA.events[0]);
  // Get the date + time object of the first event of courseB.
  const eventBDate = getDateAndTime(courseEventB.events[0]);
  // If the date of eventA is smaller or equal to eventB,
  // return -1 meaning eventA will be sorted before eventB in the list.
  if (eventADate <= eventBDate) {
    return -1;
  } // If the date of eventA is larger to eventB,
  // return 1 meaning eventA will be sorted after eventB in the list.
  else {
    return 1;
  }
}
